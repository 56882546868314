const MerchandiseTypes = {
    MERCHANDISE_GET_START: 'MERCHANDISE_GET_START',
    MERCHANDISE_GET_SUCCESS: 'MERCHANDISE_GET_SUCCESS',
    MERCHANDISE_GET_FAILURE: 'MERCHANDISE_GET_FAILURE',

    MERCHANDISE_GET_BY_ID_START: 'MERCHANDISE_GET_BY_ID_START',
    MERCHANDISE_GET_BY_ID_SUCCESS: 'MERCHANDISE_GET_BY_ID_SUCCESS',
    MERCHANDISE_GET_BY_ID_FAILURE: 'MERCHANDISE_GET_BY_ID_FAILURE',

    MERCHANDISE_ADD_START: 'MERCHANDISE_ADD_START',
    MERCHANDISE_ADD_SUCCESS: 'MERCHANDISE_ADD_SUCCESS',
    MERCHANDISE_ADD_FAILURE: 'MERCHANDISE_ADD_FAILURE',

    MERCHANDISE_UPDATE_START: 'MERCHANDISE_UPDATE_START',
    MERCHANDISE_UPDATE_SUCCESS: 'MERCHANDISE_UPDATE_SUCCESS',
    MERCHANDISE_UPDATE_FAILURE: 'MERCHANDISE_UPDATE_FAILURE',

    MERCHANDISE_DELETE_START: 'MERCHANDISE_DELETE_START',
    MERCHANDISE_DELETE_SUCCESS: 'MERCHANDISE_DELETE_SUCCESS',
    MERCHANDISE_DELETE_FAILURE: 'MERCHANDISE_DELETE_FAILURE',

}

export default MerchandiseTypes;