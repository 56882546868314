import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';

import WithSpinner from '../with-spinner/with-spinner.component';
import {selectIsFetchingMessage} from '../../redux/message/message.selector';
import QuoteOfTheDayComponent from './qoute-of-the-day.component';

const mapStateToProps = createStructuredSelector({
    isLoading: selectIsFetchingMessage
});

const QuoteOfTheDayContainer = compose(
    connect(mapStateToProps), WithSpinner
)(QuoteOfTheDayComponent);

export default QuoteOfTheDayContainer;