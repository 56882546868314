import styled from 'styled-components';

export const HomePageContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-left: 25px;
    padding-right: 10px; */
    /* padding: 10px; */

`