import MessageActionTypes from "./message.types";

export const getAllMessageStart = () => ({
    type: MessageActionTypes.MESSAGE_GET_START
})

export const getAllMessageSuccess = (message) => ({
    type: MessageActionTypes.MESSAGE_GET_SUCCESS,
    payload: message
})

export const getAllMessageFailure = (error) => ({
    type: MessageActionTypes.MESSAGE_GET_FAILURE,
    payload: error
})

export const addMessageStart = (message) => ({
    type: MessageActionTypes.MESSAGE_ADD_START,
    payload: message
})

export const addMessageSuccess = (message) => ({
    type: MessageActionTypes.MESSAGE_ADD_SUCCESS,
    payload: message
})

export const addMessageFailure = (error) => ({
    type: MessageActionTypes.MESSAGE_ADD_FAILURE,
    payload: error
})



