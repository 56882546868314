import MessageActionTypes from "./message.types";

const INITIAL_STATE = {
    isFetching: false,
    responseData:[],
    discount: null,
    error: null
}

const messageReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case MessageActionTypes.MESSAGE_GET_START:
            return {
                ...state,
                isFetching: true
            }
        case MessageActionTypes.MESSAGE_GET_SUCCESS:
            return {
                ...state,
                message: action.payload,
                isFetching: false,
                error: null
            }
        case MessageActionTypes.MESSAGE_GET_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching:false
            }
        case MessageActionTypes.MESSAGE_ADD_FAILURE:
        case MessageActionTypes.MESSAGE_ADD_SUCCESS: 
            return {
                ...state,
                responseData: action.payload
            }
        default:
            return state;
    }
}

export default messageReducer;