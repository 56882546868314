const SpeakerOpportunityActionTypes = {
    ADD_SPEAKER_OPPORTUNITY_START: 'ADD_SPEAKER_OPPORTUNITY_START',
    ADD_SPEAKER_OPPORTUNITY_SUCCESS: 'ADD_SPEAKER_OPPORTUNITY_SUCCESS',
    ADD_SPEAKER_OPPORTUNITY_FAILURE: 'ADD_SPEAKER_FAILURE',

    GET_SPEAKER_OPPORTUNITY_START: 'GET_SPEAKER_OPPORTUNITY_START',
    GET_SPEAKER_OPPORTUNITY_SUCCESS: 'GET_SPEAKER_OPPORTUNITY_SUCCESS',
    GET_SPEAKER_OPPORTUNITY_FAILURE: 'GET_SPEAKER_FAILURE'
}

export default SpeakerOpportunityActionTypes;