const ProductActionType = {
    PRODUCT_GET_START: 'PRODUCT_GET_START',
    PRODUCT_GET_SUCCESS: 'PRODUCT_GET_SUCCESS',
    PRODUCT_GET_FAILURE: 'PRODUCT_GET_FAILURE',
    PRODUCT_GET_BY_ID_START: 'PRODUCT_GET_BY_ID_START',
    PRODUCT_GET_BY_ID_SUCCESS: 'PRODUCT_GET_BY_ID_SUCCESS',
    PRODUCT_GET_BY_ID_FAILURE: 'PRODUCT_GET_BY_ID_FAILURE',

    PRODUCT_GET_BY_NAME_START: 'PRODUCT_GET_BY_NAME_START',
    PRODUCT_GET_BY_NAME_SUCCESS: 'PRODUCT_GET_BY_NAME_SUCCESS',
    PRODUCT_GET_BY_NAME_FAILURE: 'PRODUCT_GET_BY_NAME_FAILURE',

    PRODUCT_ADD_START: 'PRODUCT_ADD_START',
    PRODUCT_ADD_SUCCESS: 'PRODUCT_ADD_SUCCESS',
    PRODUCT_ADD_FAILURE: 'PRODUCT_ADD_FAILURE',
    PRODUCT_UPDATE_START: 'PRODUCT_UPDATE_START',
    PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
    PRODUCT_UPDATE_FAILURE: 'PRODUCT_UPDATE_FAILURE',
    PRODUCT_DELETE_START: 'PRODUCT_DELETE_START',
    PRODUCT_DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
    PRODUCT_DELETE_FAILURE: 'PRODUCT_DELETE_FAILURE',
    PRODUCT_TITLE_ADD_START: 'PRODUCT_TITLE_ADD_START',
    PRODUCT_TITLE_ADD_SUCCESS: 'PRODUCT_TITLE_ADD_SUCCESS',
    PRODUCT_TITLE_ADD_FAILURE: 'PRODUCT_TITLE_ADD_FAILURE',
    PRODUCT_TITLE_DELETE_START: 'PRODUCT_TITLE_DELETE_START',
    PRODUCT_TITLE_DELETE_SUCCESS: 'PRODUCT_TITLE_DELETE_SUCCESS',
    PRODUCT_TITLE_DELETE_FAILURE: 'PRODUCT_TITLE_DELETE_FAILURE',
    PRODUCT_TITLE_GET_START: 'PRODUCT_TITLE_GET_START',
    PRODUCT_TITLE_GET_SUCCESS: 'PRODUCT_TITLE_GET_SUCCESS',
    PRODUCT_TITLE_GET_FAILURE: 'PRODUCT_TITLE_GET_FAILURE',
    PRODUCT_TITLE_UPDATE_START: 'PRODUCT_TITLE_UPDATE_START',
    PRODUCT_TITLE_UPDATE_SUCCESS: 'PRODUCT_TITLE_UPDATE_SUCCESS',
    PRODUCT_TITLE_UPDATE_FAILURE: 'PRODUCT_TITLE_UPDATE_FAILURE',
}

export default ProductActionType;