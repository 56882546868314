const SpeakerActionTypes = {
    ADD_SPEAKER_START: 'ADD_SPEAKER_START',
    ADD_SPEAKER_SUCCESS: 'ADD_SPEAKER_SUCCESS',
    ADD_SPEAKER_FAILURE: 'ADD_SPEAKER_FAILURE',
    
    GET_SPEAKER_START: 'GET_SPEAKER_START',
    GET_SPEAKER_SUCCESS: 'GET_SPEAKER_SUCCESS',
    GET_SPEAKER_FAILURE: 'GET_SPEAKER_FAILURE',

    UPDATE_SPEAKER_START: 'UPDATE_SPEAKER_START',
    UPDATE_SPEAKER_SUCCESS: 'UPDATE_SPEAKER_SUCCESS',
    UPDATE_SPEAKER_FAILURE: 'UPDATE_SPEAKER_FAILURE',

    DELETE_SPEAKER_START: 'DELETE_SPEAKER_START',
    DELETE_SPEAKER_SUCCESS: 'DELETE_SPEAKER_SUCCESS',
    DELETE_SPEAKER_FAILURE: 'DELETE_SPEAKER_FAILURE',
}

export default SpeakerActionTypes;