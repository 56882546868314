const WishlistActionType = {
    ADD_WISHLIST_START : 'ADD_WISHLIST_START',
    ADD_WISHLIST_SUCCESS : 'ADD_WISHLIST_SUCCESS',
    ADD_WISHLIST_FAILURE : 'ADD_WISHLIST_FAILURE',

    GET_WISHLIST_START : 'GET_WISHLIST_START',
    GET_WISHLIST_SUCCESS : 'GET_WISHLIST_SUCCESS',
    GET_WISHLIST_FAILURE : 'GET_WISHLIST_FAILURE',

    REMOVE_WISHLIST_START : 'REMOVE_WISHLIST_START',
    REMOVE_WISHLIST_SUCCESS : 'REMOVE_WISHLIST_SUCCESS',
    REMOVE_WISHLIST_FAILURE : 'REMOVE_WISHLIST_FAILURE'
}

export default WishlistActionType;