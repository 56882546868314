const SuggestTopicActionTypes = {
    ADD_SUGGEST_TOPIC_START: 'ADD_SUGGEST_TOPIC_START',
    ADD_SUGGEST_TOPIC_SUCCESS: 'ADD_SUGGEST_TOPIC_SUCCESS',
    ADD_SUGGEST_TOPIC_FAILURE: 'ADD_SUGGEST_TOPIC_FAILURE',
    
    GET_SUGGEST_TOPIC_START: 'GET_SUGGEST_TOPIC_START',
    GET_SUGGEST_TOPIC_SUCCESS: 'GET_SUGGEST_TOPIC_SUCCESS',
    GET_SUGGEST_TOPIC_FAILURE: 'GET_SUGGEST_TOPIC_FAILURE'
}

export default SuggestTopicActionTypes;