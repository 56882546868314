import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Form, Container, Col, Row } from 'react-bootstrap';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import {addMessageStart} from '../../redux/message/message.action';

import './admin-quote-of-the-day.styles.scss';

const AdminQuoteOfTheDayComponent = ({addMessageStart}) => {
    const [productVerse, setProductVerse] = useState({
        verse: '',
        verseChapter: ''
    });
    const [buttonName, setButtonName] = useState('Submit');

    const {verse, verseChapter} = productVerse;
    
    const addProductVerse = async(event) =>{
        event.preventDefault();
        addMessageStart(productVerse);
        cancelChange();
    }
    const handleChange = (event) => {
        const {name, value} = event.target;
        setProductVerse({ ...productVerse, [name]: value });
    }
    const cancelChange = () => {
        setProductVerse({verse: '', verseChapter: ''});
    }

    return(
        <Container>
            <Col className='admin-title m-t-30'>
                <Row>
                    <h1>Message Of The Day</h1>
                </Row>
                <Row className='title-field'>
                    <Form onSubmit={addProductVerse}>
                        <Row>
                            <Col className="title">
                                <FormInput
                                    type="input"
                                    name="verseChapter"
                                    value={verseChapter}
                                    handleChange={handleChange}
                                    label="Chapter"
                                    required
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col className="title">
                                <TextareaAutosize
                                    maxRows={5}
                                    aria-label="Verse"
                                    placeholder="Message"
                                    name = 'verse'
                                    value = {verse}
                                    onChange={handleChange}
                                    style={{ 
                                        height: '200px', 
                                        border: '1px solid grey',
                                        borderRadius: '5px',
                                        minWidth: '100%',
                                        marginBottom: '10px'
                                    }}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='buttons'>
                                <CustomButton
                                    type="submit"
                                    value="submit"
                                    isNormalSubmitButton
                                >
                                    { 
                                        buttonName
                                    }
                                </CustomButton>
                                <CustomButton
                                    value="Cancel"
                                    onClick = {cancelChange}
                                    isNormalCancelButton
                                >
                                    Cancel
                                </CustomButton>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <div className='title-table'>
                    {/*<ProductVerseListContainer productType={productType} updateTitle={updateTitle} deleteProductVerse={deleteProductVerse}/>*/}
                </div>
            </Col>
        </Container>
    )
}

const mapDispatchToProps = dispatch => ({
    addMessageStart: (verse) => dispatch(addMessageStart(verse)),
    // updateProductVerseStart: (data) => dispatch(updateProductVerseStart(data)),
    // deleteProductVerseStart: (id) => dispatch(deleteProductVerseStart(id)),
    // getAllProductVerseStart: () => dispatch(getAllProductVerseStart())
});
const mapStateToProps = createStructuredSelector({
    // productType: selectProductType
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminQuoteOfTheDayComponent);