import { createSelector } from "reselect";

const selectMessage = state => state.message;

export const selectAllMessage = createSelector(
    [selectMessage],
    (message) => message ? message: []
);

export const selectIsFetchingMessage = createSelector(
    [selectMessage],
    (message) => message.isFetching
); 