const SubscriptionActionTypes = {
    ADD_SUBSCRIPTION_START: 'ADD_SUBSCRIPTION_START',
    ADD_SUBSCRIPTION_SUCCESS: 'ADD_SUBSCRIPTION_SUCCESS',
    ADD_SUBSCRIPTION_FAILURE: 'ADD_SUBSCRIPTION_FAILURE',
    
    GET_SUBSCRIPTION_START: 'GET_SUBSCRIPTION_START',
    GET_SUBSCRIPTION_SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
    GET_SUBSCRIPTION_FAILURE: 'GET_SUBSCRIPTION_FAILURE',

    UPDATE_SUBSCRIPTION_START: 'UPDATE_SUBSCRIPTION_START',
    UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_FAILURE: 'UPDATE_SUBSCRIPTION_FAILURE',

}

export default SubscriptionActionTypes;