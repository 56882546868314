const ShopActionTypes = {
    FETCH_COLLECTIONS_START: 'FETCH_COLLECTIONS_START',
    FETCH_COLLECTIONS_SUCCESS: 'FETCH_COLLECTIONS_SUCCESS',
    FETCH_COLLECTIONS_FAILURE: 'FETCH_COLLECTIONS_FAILURE',
    FETCH_COLLECTIONS_ITEM_DETAILS_START: 'FETCH_COLLECTIONS_ITEM_DETAILS_START',
    FETCH_COLLECTIONS_ITEM_DETAILS_SUCCESS: 'FETCH_COLLECTIONS_ITEM_DETAILS_SUCCESS',
    FETCH_COLLECTIONS_ITEM_DETAILS_FAILURE: 'FETCH_COLLECTIONS_ITEM_DETAILS_FAILURE',
    FETCH_COLLECTIONS_ITEM_DETAILS: 'FETCH_COLLECTIONS_ITEM_DETAILS',
    SEARCH_COLLECTIONS_START: 'SEARCH_COLLECTIONS_START',
    SEARCH_COLLECTIONS_SUCCESS: 'SEARCH_COLLECTIONS_SUCCESS',
    SEARCH_COLLECTIONS_FAILURE: 'SEARCH_COLLECTIONS_FAILURE'

}
export default ShopActionTypes;