const UserPurchaseActionType = {
    GET_USER_PURCHASE_START : 'GET_USER_PURCHASE_START',
    GET_USER_PURCHASE_SUCCESS : 'GET_USER_PURCHASE_SUCCESS',
    GET_USER_PURCHASE_FAILURE : 'GET_USER_PURCHASE_FAILURE',
    
    ADD_USER_PURCHASE_START : 'ADD_USER_PURCHASE_START',
    ADD_USER_PURCHASE_SUCCESS : 'ADD_USER_PURCHASE_SUCCESS',
    ADD_USER_PURCHASE_FAILURE : 'ADD_USER_PURCHASE_FAILURE',

    UPDATE_USER_PURCHASE_DELIVERY_STATUS_START : 'UPDATE_USER_PURCHASE_DELIVERY_STATUS_START',
    UPDATE_USER_PURCHASE_DELIVERY_STATUS_SUCCESS : 'UPDATE_USER_PURCHASE_DELIVERY_STATUS_SUCCESS',
    UPDATE_USER_PURCHASE_DELIVERY_STATUS_FAILURE : 'UPDATE_USER_PURCHASE_DELIVERY_STATUS_FAILURE',

    SEARCH_USER_PURCHASE_START : 'SEARCH_USER_PURCHASE_START',
    SEARCH_USER_PURCHASE_SUCCESS : 'SEARCH_USER_PURCHASE_SUCCESS',
    SEARCH_USER_PURCHASE_FAILURE : 'SEARCH_USER_PURCHASE_FAILURE'

    
}

export default UserPurchaseActionType;