import { put, all, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { host } from '../../api.config';
import MessageActionTypes from './message.types';
import {unAuthorized} from '../../factory';
import { 
    addMessageSuccess,
    addMessageFailure,
    getAllMessageSuccess,
    getAllMessageFailure
} from './message.action';

const MySwal = withReactContent(Swal);

export function* addMessageStartSaga({payload: {verse, verseChapter}}) {
    try {
        const message = yield axios.post(`${host}/api/message`, {verse, verseChapter});
        if(message.status === 200 || message.status === 201){
            yield put(addMessageSuccess(message.data));
            MySwal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Message Added Successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    } catch (error) {
        let err = error.response;
        console.log(err);
        MySwal.fire({
            position: 'top-end',
            icon: 'error',
            title: err,
            showConfirmButton: false,
            timer: 1500
        });
        yield put(addMessageFailure(error));
        yield put(unAuthorized(error));
    }
}

export function* getMessageStartSaga() {
    try {
        const message = yield axios.get(`${host}/api/message`);
        yield put(getAllMessageSuccess(message.data));
    } catch (error) {
        let err = error.response.data;
        MySwal.fire({
            position: 'top-end',
            icon: 'error',
            title: err,
            showConfirmButton: false,
            timer: 1500
        });
        yield put(getAllMessageFailure(error));
        yield put(unAuthorized(error));
    }
}

export function* onMessageAddStart() {
    yield takeLatest(MessageActionTypes.MESSAGE_ADD_START, addMessageStartSaga);
}
export function* onMessageGetStart() {
    yield takeLatest(MessageActionTypes.MESSAGE_GET_START, getMessageStartSaga);
}
export function* MessageSaga() {
    yield all([
        call(onMessageAddStart),
        call(onMessageGetStart),
    ]);
}