const MessageActionTypes = {
    MESSAGE_GET_START: 'MESSAGE_GET_START',
    MESSAGE_GET_SUCCESS: 'MESSAGE_GET_SUCCESS',
    MESSAGE_GET_FAILURE: 'MESSAGE_GET_FAILURE',
    
    MESSAGE_ADD_START: 'MESSAGE_ADD_START',
    MESSAGE_ADD_SUCCESS: 'MESSAGE_ADD_SUCCESS',
    MESSAGE_ADD_FAILURE: 'MESSAGE_ADD_FAILURE'
}

export default MessageActionTypes;