const DiscountActionTypes = {
    DISCOUNT_GET_START: 'DISCOUNT_GET_START',
    DISCOUNT_GET_SUCCESS: 'DISCOUNT_GET_SUCCESS',
    DISCOUNT_GET_FAILURE: 'DISCOUNT_GET_FAILURE',
    DISCOUNT_GET_BY_ID_START: 'DISCOUNT_GET_BY_ID_START',
    DISCOUNT_GET_BY_ID_SUCCESS: 'DISCOUNT_GET_BY_ID_SUCCESS',
    DISCOUNT_GET_BY_ID_FAILURE: 'DISCOUNT_GET_BY_ID_FAILURE',
    DISCOUNT_ADD_START: 'DISCOUNT_ADD_START',
    DISCOUNT_ADD_SUCCESS: 'DISCOUNT_ADD_SUCCESS',
    DISCOUNT_ADD_FAILURE: 'DISCOUNT_ADD_FAILURE',
    DISCOUNT_UPDATE_START: 'DISCOUNT_UPDATE_START',
    DISCOUNT_UPDATE_SUCCESS: 'DISCOUNT_UPDATE_SUCCESS',
    DISCOUNT_UPDATE_FAILURE: 'DISCOUNT_UPDATE_FAILURE',
    DISCOUNT_DELETE_START: 'DISCOUNT_DELETE_START',
    DISCOUNT_DELETE_SUCCESS: 'DISCOUNT_DELETE_SUCCESS',
    DISCOUNT_DELETE_FAILURE: 'DISCOUNT_DELETE_FAILURE',
    DISCOUNT_GET_BY_NAME_START: 'DISCOUNT_GET_BY_NAME_START',
    DISCOUNT_GET_BY_NAME_SUCCESS: 'DISCOUNT_GET_BY_NAME_SUCCESS',
    DISCOUNT_GET_BY_NAME_FAILURE: 'DISCOUNT_GET_BY_NAME_FAILURE',
}

export default DiscountActionTypes;