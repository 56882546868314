import React from "react";
import DiscountComponent from "../../components/discount/discount.component";

import './discount.styles.scss';

const DiscountPage = () => {
    return (
        <DiscountComponent/>
    );
}

export default DiscountPage;