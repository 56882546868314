import React, {useRef, useEffect} from 'react';
import ReactPlayer from 'react-player/lazy'

import './webinar-player.styles.scss';

const WebinarPlayerComponent = () => {

    const handleOnPlay = () => {
        console.log('From OnPlay');
    }

    const handleOnStart = () => {
        console.log('From OnStart');
    }

    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
      }, []);

    return(
        <div className='player-wrapper'>
           { /*<ReactPlayer
                className='react-player'
                url='https://www.youtube.com/watch?v=D0UnqGm_miA'
                width='100%'
                height='100%'
                onPlay={handleOnPlay}
                onStart = {handleOnStart}
                
            />*/}
            <video
                style={{ maxWidth: "100%", width: "80%" }}
                playsInline
                loop
                muted
                controls
                alt="All the devices"
                src="https://stream.mux.com/6fiGM5ChLz8T66ZZiuzk1KZuIKX8zJz00/medium.mp4"
                ref={videoEl}
            />
        </div>
    );
}

export default WebinarPlayerComponent;