const BannerActionType = {
    BANNER_ADD_START: 'BANNER_ADD_START',
    BANNER_ADD_SUCCESS: 'BANNER_ADD_SUCCESS',
    BANNER_ADD_FAILURE: 'BANNER_ADD_FAILURE',
    BANNER_DELETE_START: 'BANNER_DELETE_START',
    BANNER_DELETE_SUCCESS: 'BANNER_DELETE_SUCCESS',
    BANNER_DELETE_FAILURE: 'BANNER_DELETE_FAILURE',
    BANNER_GET_START: 'BANNER_GET_START',
    BANNER_GET_SUCCESS: 'BANNER_GET_SUCCESS',
    BANNER_GET_FAILURE: 'BANNER_GET_FAILURE',
    BANNER_UPDATE_START: 'BANNER_UPDATE_START',
    BANNER_UPDATE_SUCCESS: 'BANNER_UPDATE_SUCCESS',
    BANNER_UPDATE_FAILURE: 'BANNER_UPDATE_FAILURE',
}

export default BannerActionType;